const translations = {
    clearsky_day: "jasno",
    clearsky_night: "jasno",
    clearsky_polartwilight: "",
    cloudy: "oblacno",
    fair_day: "",
    fair_night: "",
    fair_polartwilight: "",
    fog: "",
    heavyrain: "",
    heavyrainandthunder: "",
    heavyrainshowersandthunder_day: "",
    heavyrainshowersandthunder_night: "",
    heavyrainshowersandthunder_polartwilight: "",
    heavyrainshowers_day: "",
    heavyrainshowers_night: "",
    heavyrainshowers_polartwilight: "",
    heavysleet: "",
    heavysleetandthunder: "",
    heavysleetshowersandthunder_day: "",
    heavysleetshowersandthunder_night: "",
    heavysleetshowersandthunder_polartwilight: "",
    heavysleetshowers_day: "",
    heavysleetshowers_night: "",
    heavysleetshowers_polartwilight: "",
    heavysnow: "",
    heavysnowandthunder: "",
    heavysnowshowersandthunder_day: "",
    heavysnowshowersandthunder_night: "",
    heavysnowshowersandthunder_polartwilight: "",
    heavysnowshowers_day: "",
    heavysnowshowers_night: "",
    heavysnowshowers_polartwilight: "",
    lightrain: "",
    lightrainandthunder: "",
    lightrainshowersandthunder_day: "",
    lightrainshowersandthunder_night: "",
    lightrainshowersandthunder_polartwilight: "",
    lightrainshowers_day: "",
    lightrainshowers_night: "",
    lightrainshowers_polartwilight: "",
    lightsleet: "",
    lightsleetandthunder: "",
    lightsleetshowers_day: "",
    lightsleetshowers_night: "",
    lightsleetshowers_polartwilight: "",
    lightsnow: "",
    lightsnowandthunder: "",
    lightsnowshowers_day: "",
    lightsnowshowers_night: "",
    lightsnowshowers_polartwilight: "",
    lightssleetshowersandthunder_day: "",
    lightssleetshowersandthunder_night: "",
    lightssleetshowersandthunder_polartwilight: "",
    lightssnowshowersandthunder_day: "",
    lightssnowshowersandthunder_night: "",
    lightssnowshowersandthunder_polartwilight: "",
    partlycloudy_day: "",
    partlycloudy_night: "",
    partlycloudy_polartwilight: "",
    rain: "",
    rainandthunder: "",
    rainshowersandthunder_day: "",
    rainshowersandthunder_night: "",
    rainshowersandthunder_polartwilight: "",
    rainshowers_day: "",
    rainshowers_night: "",
    rainshowers_polartwilight: "",
    sleet: "",
    sleetandthunder: "",
    sleetshowersandthunder_day: "",
    sleetshowersandthunder_night: "",
    sleetshowersandthunder_polartwilight: "",
    sleetshowers_day: "",
    sleetshowers_night: "",
    sleetshowers_polartwilight: "",
    snow: "",
    snowandthunder: "",
    snowshowersandthunder_day: "",
    snowshowersandthunder_night: "",
    snowshowersandthunder_polartwilight: "",
    snowshowers_day: "",
    snowshowers_night: "",
    snowshowers_polartwilight: "",
}
export default translations;